import React, { useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { IoStarSharp } from 'react-icons/io5';

import './MainPage.scss';

const MainPage = ({ cafes }) => {
  const getCafeStatus = useCallback((cafe) => {
    const localTimestamp = moment.tz('Europe/Moscow');
    const currentTime = localTimestamp.format('HH:mm');
    return currentTime >= cafe.opening_time && currentTime < cafe.closing_time
      ? 'open'
      : 'open';
  }, []);

  const cafeStatuses = useMemo(() => {
    return cafes.reduce((acc, cafe) => {
      acc[cafe.id] = getCafeStatus(cafe);
      return acc;
    }, {});
  }, [cafes, getCafeStatus]);

  return (
    <div className="main-page">
      <div className="main-page__title">Выберите кафе</div>
      <div className="cafes-list">
        {cafes.map((cafe) => {
          const status = cafeStatuses[cafe.id];
          const isClosed = status === 'closed';

          return (
            <Link
              key={cafe.id}
              to={`/cafe/${cafe.id}`}
              className={`cafe-item ${isClosed ? 'disabled' : ''}`}
              style={{ pointerEvents: isClosed ? 'none' : 'auto' }}
            >
              <div className="cafe-item__info">
                <div className="cafe-image-block">
                  <img
                    src={`${cafe.image_url.String}`}
                    alt={cafe.name}
                    className="cafe-image"
                    loading="lazy"
                  />
                  <div className="cafe-image__time">
                    {cafe.opening_time} - {cafe.closing_time}
                  </div>
                </div>
                <div className="cafe-item__info__desc">
                  <div className="name_and_rating">
                    <div className="name">
                      {cafe.name}
                      {isClosed && <div className="cafe-status">Закрыто</div>}
                    </div>

                    <div className="rating">
                      <div className="rating__up">
                        <div className="rating__up__icon">
                          <IoStarSharp size={11} />
                        </div>
                        <div className="rating__up__count">
                          <div>{cafe.average_rating}</div>
                        </div>
                      </div>
                      <div className="rating__hr" />
                      <div className="rating__down">
                        <div>{cafe.number_of_reviews}</div>
                      </div>
                    </div>
                  </div>
                  <div className="phone_and_address">
                    <p>{cafe.phone}</p>
                    <p>{cafe.address}</p>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default MainPage;
