import moment from 'moment-timezone';
import React from 'react';

export const useCurrentTheme = (tg) => {
  React.useEffect(() => {
    const updateTheme = () => {
      const localTimestamp = moment.tz('Europe/Moscow');
      const currentTime = localTimestamp.format('HH:mm');
      const timeBasedTheme =
        currentTime >= '19:00' || currentTime < '07:00' ? 'dark' : 'light';

      const telegramTheme = tg.themeParams?.theme || timeBasedTheme;

      document.documentElement.setAttribute('data-theme', telegramTheme);

      const colors =
        telegramTheme === 'dark'
          ? { color: '#1f1f1f', textColor: '#ffffff' }
          : { color: '#ffffff', textColor: '#000000' };

      tg.setHeaderColor(colors.color);
      tg.setBackgroundColor(colors.color);
      tg.setBottomBarColor(colors.color);
    };

    updateTheme();
  }, [tg]);
};
