import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTelegram } from './hooks/useTelegram';
import { fetchCafes } from './redux/cafesSlice';

import Layout from './components/Layout';
import MainPage from './pages/MainPage';
import CafeMenuPage from './pages/CafeMenuPage';
import CartPage from './pages/CartPage';
import StatusHandler from './components/StatusHandler';
import { useCurrentTheme } from './hooks/useCurrentTheme';

function App() {
  const { tg } = useTelegram();
  const dispatch = useDispatch();

  const { status, cafes } = useSelector((state) => state.cafes);

  React.useEffect(() => {
    tg.ready();
    tg.expand();
    tg.disableVerticalSwipes();
    tg.enableClosingConfirmation();
    tg.MainButton.color = '#45a049';
    tg.MainButton.textColor = '#ffffff';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useCurrentTheme(tg);

  React.useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchCafes());
    }
  }, [status, dispatch]);

  return (
    <StatusHandler status={status}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<MainPage cafes={cafes} />} />
            <Route path="/cafe/:cafeId" element={<CafeMenuPage />} />
            <Route path="/cart" element={<CartPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </StatusHandler>
  );
}

export default App;
