import React from 'react';
import Loading from '../Loading';
import LoadingError from '../Loading/LoadingError';

const StatusHandler = ({ status, children }) => {
  if (status === 'loading' || status === 'idle') {
    return <Loading />;
  }
  if (status === 'failed') {
    return <LoadingError />;
  }
  return children;
};

export default StatusHandler;
